import { isLocalhost } from '../helpers/isLocalHost';

const TWO_SECONDS = 2000;
const { REACT_APP_ENVIRONMENT: env, NODE_ENV } = process.env;

let host = window.location.origin;

if (NODE_ENV === 'development' || isLocalhost()) {
    const subdomainPrefix = env === 'prod' ? '' : `${env}-`;
    host = `https://${subdomainPrefix}controller.docabode.com`;
}

let baseUrl: string;
let rootUrl = `${host}/api`;
let graphqlUrl = `${host}/graphql/`;
let orgUrl = `${rootUrl}/org`;
let graphqlV2Url = process.env.REACT_APP_GRAPHQL_V2 || `${rootUrl}/v2/graphql/`;

const headers: { [key: string]: string } = {};

const setOrganisation = (org: string) => {
    baseUrl = `${rootUrl}/controller/org/${org}`;
    orgUrl = `${rootUrl}/org/${org}`;
    headers['x-docabode-organisation'] = org;
};

const retryable = <T>(callback: () => Promise<Response>, retries = 0): Promise<T> => {
    let tries = 0;

    return new Promise((resolve, reject) => {
        const execute = async () => {
            try {
                tries += 1;
                const response = await callback();
                const data = await response.json();

                if (!response.ok) {
                    let errorMessage = 'No error message provided';
                    if (data.message || typeof data.error === 'string') {
                        errorMessage = data.message || data.error;
                    }

                    const error = new Error(errorMessage);
                    // @ts-ignore todo
                    error.response = response;
                    // @ts-ignore todo
                    error.data = data;
                    throw error;
                }

                resolve(data);
            } catch (err) {
                console.warn('Request failed:', err);

                if (tries <= retries) {
                    setTimeout(execute, TWO_SECONDS);
                } else {
                    console.log('Request reached retry limit');
                    reject(err);
                }
            }
        };

        execute();
    });
};

export {
    setOrganisation,
    baseUrl,
    rootUrl,
    retryable,
    graphqlUrl,
    headers,
    orgUrl,
    host,
    graphqlV2Url,
};
