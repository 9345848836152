import { JobStatus, Patient } from '@doc-abode/data-models';
import { FormikValues } from 'formik';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { getStringEndTime } from '../../../../../helpers';
import { shouldShowPostJobNotes } from '../../../../../helpers/shouldShowPostJobNotes';
import { getAllWarnings } from '../../../../../helpers/ucr';
import getHcpName from '../../../../../helpers/ucr/getHcpName';
import { IconChangeStatus } from '../../../../../helpers/ucr/icons';
import useStores from '../../../../../hook/useStores';
import { WarningType } from '../../../../../interfaces/ucr';
import RootStore from '../../../../../stores/RootStore';
import { DialogAlerts, Dialogs } from '../../../../../stores/UCRStore';
import { ConditionalDisplay } from '../../../../CondtionalDisplay';
import {
    Accordion,
    AccordionColors,
    AccordionTab,
    Button,
    ButtonElems,
    ButtonSizes,
} from '../../../../v2/components';
import { Text } from '../../../../v2/form';
import Warnings from '../../../../v2/form/Warnings';
import { TimeHelperActualEnd, TimeHelperActualStart } from '../../blocks/panels/TimeHelper';
import { usePatientFlagsToChangeStatus } from '../../hooks/usePatientFlagsToChangeStatus';
import PatientDetailsReviewForm from '../PatientDetails/PatientDetailsReviewForm';
import { FormMode, FormSteps } from '../common';

interface Props {
    values: FormikValues;
    onSave: (values: FormikValues) => Promise<void>;
    onEdit: (step: FormSteps) => void;
    formMode: string;
    setVisitData: (values: FormikValues | null) => void;
}

const ReviewForm: FC<Props> = ({ values, onSave, onEdit, formMode, setVisitData }) => {
    const {
        RootStore: {
            usersStore: { users },
            ucrStore: { openedDialogAlert, setOpenedDialogAlert, setOpenedDialog, warnings, jobs },
            configStore: { pathways, adminTypes },
        },
    } = useStores() as { RootStore: RootStore };

    const patient: Patient = jobs.filter((user: any) => user.id === values.id)[0];
    const { isDisabled } = usePatientFlagsToChangeStatus(patient);

    if (
        openedDialogAlert !== DialogAlerts.SAVE_ADMINISTRATIVE_TIME &&
        formMode !== FormMode.EDIT_VISIT
    ) {
        setOpenedDialogAlert(DialogAlerts.SAVE_ADMINISTRATIVE_TIME);
    }

    // Wrapping in an array to keep changes to downstream dependencies minimal
    const staffFiltered = [getHcpName(users, values.hcpId, false)];

    const schedulingWarnings: WarningType[] = warnings ? warnings[values?.id || ''] : [];
    const [visitStepWarnings, setVisitStepWarnings] = useState<any>({});

    useEffect(() => {
        const allStepWarnings = getAllWarnings(values, pathways, adminTypes, true);
        setVisitStepWarnings(allStepWarnings);
    }, [adminTypes, pathways, values]);

    return (
        <Accordion>
            <AccordionTab
                name="patiens"
                title="Patient details"
                onEdit={() => onEdit(FormSteps.PATIENT)}
                color={AccordionColors.PINK}
                open
            >
                <PatientDetailsReviewForm values={values} />
            </AccordionTab>
            <AccordionTab
                name="activity"
                title="Activity details"
                onEdit={() => onEdit(FormSteps.ACTIVITY)}
                color={AccordionColors.PINK}
                open
            >
                <CareDetails values={values} staffFiltered={staffFiltered} patient={patient} />
            </AccordionTab>
            {(schedulingWarnings || (visitStepWarnings && visitStepWarnings.length > 0)) && (
                <Warnings warnings={schedulingWarnings} stepWarnings={visitStepWarnings} />
            )}
            {formMode !== FormMode.EDIT_VISIT && (
                <div>
                    <label className="v2__form-group">
                        <Button
                            className="v2__form-submit-button"
                            elem={ButtonElems.DIV}
                            name={'Add administrative time'}
                            size={ButtonSizes.MEDIUM}
                            clickEvent={() => onSave(values)}
                        />
                    </label>
                </div>
            )}

            {isDisabled ? (
                <Button
                    className="v2__form-submit-button"
                    size={ButtonSizes.MEDIUM}
                    name="Change status"
                    Icon={IconChangeStatus}
                    clickEvent={() => setOpenedDialog(Dialogs.CHANGE_VISIT_STATUS)}
                />
            ) : null}
        </Accordion>
    );
};

export default ReviewForm;

function CareDetails({
    values,
    staffFiltered,
    patient,
}: {
    values: FormikValues;
    staffFiltered: string[];
    patient: Patient;
}) {
    const jobStatus: JobStatus = values.jobStatus;
    const showPatientNotes = shouldShowPostJobNotes({ jobStatus });

    return (
        <div className="v2__form-section">
            <Text name="activityType" title="Activity" description={values.activityType} />
            <Text
                name="visitDate"
                title="Planned date of job"
                description={moment(values.visitDate).format('dddd Do MMMM YYYY')}
            />
            <Text
                name="startTime"
                title="Planned start time"
                description={values.startTime ? moment(values.startTime).format('hh:mm a') : ''}
            />
            <Text name="duration" title="Planned duration" description={values.duration} />
            <Text
                name="endTime"
                title="Expected end time"
                description={getStringEndTime(
                    values.startTime || values.startDateTime,
                    values.duration,
                    'hh:mm a',
                )}
            />
            <Text
                name="Notes"
                title="Pre-job notes"
                className="visit-details__notes"
                description={values.notes}
            />
            <Text name="staffRequired" title="Staffing requirements" description={staffFiltered} />
            <Text
                name="arrivedDateTime"
                title="Actual start time"
                notSetText="Not yet recorded"
                description={TimeHelperActualStart({ patient: patient, isFirstUser: true })}
            />
            <Text
                name="finishedDateTime"
                title="Actual end time"
                notSetText="Not yet recorded"
                description={TimeHelperActualEnd({ patient: patient, isFirstUser: true })}
            />
            <ConditionalDisplay show={showPatientNotes}>
                <Text
                    name="postVisitNotes"
                    title="Post-job notes"
                    className="visit-details__notes"
                    description={values.postVisitNotes}
                />
            </ConditionalDisplay>
        </div>
    );
}
