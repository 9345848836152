import { Provider } from 'mobx-react';

import RootStore from '../stores/RootStore';
import Authenticator from './auth/Authenticator';
import App from './App';
import Footer from './layout/Footer';
import { useServiceWorker } from '../hook/useServiceWorker';
import { EnvironmentInfo } from './modules/callout/EnvironmentInfo';
import { ServiceWorkerUpdate } from './modules/callout/ServiceWorkerUpdate';

const rootStore = new RootStore();

export const AppShell = () => {
    const { isUpdateReady, applyUpdate } = useServiceWorker();

    const callouts = [];

    if (process.env.REACT_APP_ENVIRONMENT !== 'prod') {
        callouts.push(<EnvironmentInfo key="environment-info" />);
    }

    if (isUpdateReady) {
        callouts.push(
            <ServiceWorkerUpdate applyUpdate={applyUpdate} key="service-worker-update" />,
        );
    }

    return (
        <>
            <div className={`container container--callout-x${callouts.length}`}>
                {callouts}
                <Provider RootStore={rootStore}>
                    <Authenticator RootStore={rootStore}>
                        <App />
                    </Authenticator>
                    <Footer />
                </Provider>
            </div>
            <div id="custom-prompt"></div>
        </>
    );
};
