import moment from 'moment-timezone';

moment.locale('en-UK');

export const capitalize = (text) => (text ? text.charAt(0).toUpperCase() + text.slice(1) : text);

export const formatAPIMultiSelect = (value) => value.map((fieldValue) => fieldValue.value);

export const formatDateOfBirth = (date) =>
    moment.utc(moment(new Date(date)).format('YYYY-MM-DD')).format();

export const formatParserAPIDate = (date) => new Date(date + ' UTC').toISOString();

export const parseDateStringToJSDate = (dateString) => moment(dateString, 'DD MMM YYYY').toDate();

export const formatDisplayDate = (date) => moment(new Date(date)).format('DD MMM YYYY');

export const formatDisplayDateTime = (dateTime) =>
    moment(new Date(dateTime)).format('HH:mm on D MMM YYYY');

export const formatDisplayTime = (dateTime) => moment(new Date(dateTime)).format('HH:mm');

export const getAgeFromDate = (date) => moment(date).diff(new Date(), 'years') * -1;

export const addPhoneCountryCode = (phone) => (phone[0] === '+' ? phone : `+44${phone}`);

export const removePhoneLeadingZero = (phone) =>
    parseInt(phone[0], 0) === 0 ? phone.substring(1) : phone;

export const getTimeRemaining = (dateTime) =>
    (moment(dateTime).diff(moment(new Date())) / 1000 / 60).toFixed(0);

export const isJobExpired = (expiryDateTime) => 0 >= getTimeRemaining(expiryDateTime);

export const formatPractice = (name, odsCode) =>
    `${name || 'GP practice unknown'}${odsCode ? ` (${odsCode})` : ''}`;

export const formatPractices = (practices) =>
    practices.map((practice) => ({
        label: `${practice.name}${practice.odsCode ? ` ${practice.odsCode}` : ''}`,
        value: `${practice.name}${practice.odsCode ? ` ${practice.odsCode}` : ''}`,
    }));

export const splitPracticeDetails = (practice) => {
    const slicePoint = practice.lastIndexOf(' ');
    const name = practice.slice(0, slicePoint);
    const odsCode = practice.slice(slicePoint + 1);
    return { name, odsCode };
};

export const metresToMiles = (metres) => `${((metres / 1000 / 8) * 5).toFixed(1)} miles`;

export const formatTimeDifference = (a, b) => {
    return moment.duration(moment(a).diff(moment(b))).humanize(true);
};

export const timeDifferenceInDays = (a, b) =>
    Math.floor(moment.duration(moment(b).startOf('day').diff(moment(a).startOf('day'))).as('days'));

export const timeDifferenceInWeeks = (a, b) =>
    Math.floor(moment.duration(moment(b).diff(moment(a))).as('weeks'));

export const formatTimeDifferenceInWeeks = (a, b) => {
    const weeks = timeDifferenceInWeeks(a, b);
    if (weeks === 0) {
        return 'Less than a week ago';
    }
    if (weeks === 1) {
        return '1 week ago';
    }
    return `${weeks} weeks ago`;
};

export const formatTimeWithDate = (time, date) => {
    const [hour, minute] = time.split(':');
    return moment(date).hour(hour).minute(minute).startOf('minute').toISOString();
};

export const formatDateWithTime = (date) => {
    return moment(date).format('HH:mm on DD/MM/YYYY');
};

export const formatTime = (date) => {
    return moment(date).format('HH:mm');
};

export const formatTimeAMPM = (date) => moment(date).format('hh:mm a');

export const formatDateToStr = (date) => {
    return moment.utc(date).format('dddd Do MMMM YYYY');
};

export const getStartAndEndDay = (date) => {
    const start = moment(date).startOf('day').toISOString();
    const end = moment(date).endOf('day').toISOString();

    return [start, end];
};

export const getMidnightDayString = (date) => {
    return moment(date).startOf('day').toISOString();
};
