import { FC, useState, useEffect, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { Formik, FormikValues } from 'formik';
import moment from 'moment';
import { cloneDeep } from 'lodash';

import { Patient, JobStatus } from '@doc-abode/data-models';
import { IHcp } from '../../../../../interfaces/ucr';
import AddVisitForm from './AddVisitForm';
import { VisitData, APIVisitData } from './AddVisitTypes';
import ReviewForm from './ReviewForm';
import validationSchema from './validation';
import { FormMode, FormSteps } from '../common';

import { Dialogs, DialogAlerts } from '../../../../../stores/UCRStore';
import AppToaster from '../../../../modules/helpers/Toaster';
import useStores from '../../../../../hook/useStores';
import { CREATE_JOB } from '../../../../../graphql/queries/jobs';
import { getMidnightDayString } from '../../../../modules/helpers/formatData';
import { JobsContext } from '../../../../../providers';
import { useView } from '../../views/useView';

export const formatToApiData = (
    values: VisitData,
    org: string,
    username: string,
    users?: IHcp[],
) => {
    const visitDate = moment(values.visitDate).seconds(0);
    const startTime = moment(values.startTime).seconds(0);

    const data = {
        ...cloneDeep(values as APIVisitData),
        createDateTime: moment().seconds(0).toISOString(),
        createdBy: username,
        startDateTime: values.startTime
            ? visitDate.clone().hour(startTime.hour()).minute(startTime.minute()).toISOString()
            : null,
        dateOfVisit: getMidnightDayString(visitDate),
        duration: values.duration,
        dateOfBirth: moment(values.dateOfBirth).toISOString(),
        jobStatus: values.hcpId ? JobStatus.ACCEPTED : JobStatus.PENDING,
        jobType: 'ucr',
        lastUpdatedBy: username,
        organisation: org,
        version: 0,
        hcpName: users?.find((user: any) => values.hcpId === user.userId)?.userName,
        hcpId: values.hcpId || undefined,
        buddyId: values.buddyId || undefined,
        pds: values.pds?.versionId ? { versionId: values.pds?.versionId } : undefined,
    };

    if (values.referralDateTime) {
        data.referralDateTime = moment(values.referralDateTime).toISOString();
    }

    if (values.availableFrom) {
        const availableFromHours = moment(values.availableFrom).get('hours');
        const availableFromMinutes = moment(values.availableFrom).get('minutes');

        data.availableFrom = visitDate
            .clone()
            .hour(availableFromHours)
            .minute(availableFromMinutes)
            .seconds(0)
            .toISOString();
    }

    if (values.availableTo) {
        const availableToHours = moment(values.availableTo).get('hours');
        const availableToMinutes = moment(values.availableTo).get('minutes');

        data.availableTo = visitDate
            .clone()
            .hour(availableToHours)
            .minute(availableToMinutes)
            .seconds(0)
            .toISOString();
    }

    if (values.staffRequired === 2) {
        if (values.buddyId) {
            data.buddyName = users?.find((user: any) => values.buddyId === user.userId)?.userName;
            data.buddyJobStatus = data.buddyJobStatus || JobStatus.ACCEPTED;
        } else {
            data.buddyJobStatus = JobStatus.PENDING;
        }
    } else {
        delete data.buddyJobStatus;
    }

    if (values.pds?.versionId) {
        data.pds = {
            versionId: values.pds?.versionId,
        };
    }

    delete data.hcpIdTemp;
    delete data.buddyIdTemp;
    delete data.visitDate;
    delete data.startTime;
    delete data.endTime;
    delete data.jobStatusBeforeAbort;
    delete data.buddyJobStatusBeforeAbort;
    delete data.controllerReverseNotes;
    delete data.controllerReverseReason;
    delete data.hcpReverseNotes;
    delete data.hcpReverseReason;
    delete data.buddyControllerReverseNotes;
    delete data.buddyControllerReverseReason;
    delete data.buddyHcpReverseNotes;
    delete data.buddyHcpReverseReason;
    delete data.abortedBy;
    delete data.buddyAbortedBy;
    delete data.fromReferral;
    delete data.buddyHcpAbortedDateTime;

    return data;
};

const AddVisit: FC = () => {
    const [createJob, { loading, error }] = useMutation(CREATE_JOB);
    const {
        RootStore: {
            ucrStore: { followUpVisitData, setFollowUpVisitData },
        },
    } = useStores() as { RootStore: any };
    const [isJobCreated, setIsJobCreated] = useState<boolean>(false);

    const jobsContext = useContext(JobsContext);

    const { currentViewState } = useView();

    const [formData, setFormData] = useState<{
        step: FormSteps;
        values?: VisitData | null;
        initialValues?: VisitData | null;
        formMode: FormMode;
    }>({
        step: followUpVisitData ? FormSteps.CARE : FormSteps.PATIENT,
        formMode: followUpVisitData ? FormMode.FOLLOW_UP : FormMode.DEFAULT,
    });

    // this really needs to be typed better.
    // removing buddy status times as if followUpVisitData
    // doesn't exist then how could it be possible to fetch these values.
    const initialData: any = followUpVisitData ||
        formData.initialValues || {
            ...Patient.INITIAL_VALUES,
            buddyMadeCurrentDateTime: null,
            buddyArrivedDateTime: null,
            buddyFinishedDateTime: null,
            referralDateTime: undefined,
            dateOfBirth: new Date(),
            visitDate: new Date(),
            availableFrom: null,
            availableTo: null,
            startTime: null,
            staffPreferredGender: [],
            duration: '01:00',
        };

    delete initialData.buddyHcpGrade;
    delete initialData.buddyHcpType;

    if (followUpVisitData) {
        if (moment(followUpVisitData.visitDate).isBefore(moment(), 'day')) {
            initialData.visitDate = moment().toDate();
        }
        if (followUpVisitData.hcpId) {
            initialData.hcpIdTemp = followUpVisitData.hcpId;
            delete initialData.hcpId;
        }
        if (followUpVisitData.buddyId) {
            initialData.buddyIdTemp = followUpVisitData.buddyId;
            delete initialData.buddyId;
        }
        initialData.staffRequired = 1;
        delete initialData.startTime;
    }

    const {
        RootStore: {
            configStore: { org },
            ucrStore: { setOpenedDialog, setOpenedDialogAlert, selectedDate },
            userStore: {
                user: { username },
            },
            usersStore: { users },
        },
    } = useStores() as { RootStore: any };

    const onSubmit = async (values: FormikValues) => {
        const newValues = {
            ...(cloneDeep(values) as VisitData),
            contactNumber: values?.contactNumber ? values?.contactNumber : undefined,
            additionalContactNumbers: values?.additionalContactNumbers
                ? values?.additionalContactNumbers.filter((str: string) => str)
                : undefined,
        };

        setFormData((data) => ({
            ...data,
            values: newValues,
        }));
    };

    const saveVisitData = async () => {
        if (!formData.values) return;

        const formattedData = formatToApiData(formData.values, org, username, users);
        try {
            await createJob({ variables: { input: formattedData } });
            setIsJobCreated(true);
        } catch (err) {
            console.log('Error creating visit', err);
            AppToaster.show({
                message: 'Sorry, an error occurred and we were unabled to create the visit',
                intent: 'danger',
            });
            return;
        }

        AppToaster.show({
            message: 'Visit added successfully!',
            intent: 'success',
        });

        setFormData((data) => ({
            ...data,
            initialValues: null,
        }));
        setFollowUpVisitData(null);

        setOpenedDialog(Dialogs.NONE);

        if (!currentViewState.patientList) {
            const isUnassigned =
                (formData.values?.staffRequired !== 2 && !formData.values?.hcpId) ||
                (formData.values?.staffRequired === 2 &&
                    !formData.values?.hcpId &&
                    !formData.values?.buddyId);

            if (
                isUnassigned ||
                moment(formData.values?.visitDate).isSame(moment(selectedDate), 'day')
            ) {
                jobsContext.setRefreshAssignedJobs(true);
            }
        } else {
            jobsContext.setRefreshPatients(true);
        }
    };

    const onEditReviewForm = (step: FormSteps) => {
        setOpenedDialogAlert(DialogAlerts.SAVE_VISIT);
        setFormData((data) => ({
            step,
            initialValues: cloneDeep(data.values),
            formMode: FormMode.ADD_VISIT,
        }));
    };

    useEffect(() => {
        setOpenedDialogAlert(!formData.values ? DialogAlerts.SAVE_VISIT : DialogAlerts.NONE);
    }, [formData.values, setOpenedDialogAlert]);

    useEffect(() => {
        return () => setFollowUpVisitData(null);
    });

    return (
        <Formik initialValues={initialData} validationSchema={validationSchema} onSubmit={onSubmit}>
            {formData.values ? (
                <ReviewForm
                    values={formData.values}
                    formMode={formData.formMode}
                    onEdit={onEditReviewForm}
                    onSave={saveVisitData}
                    isJobCreated={isJobCreated}
                />
            ) : (
                <AddVisitForm
                    step={formData.step}
                    loading={loading}
                    error={error}
                    formMode={formData.formMode}
                    onSubmit={onSubmit}
                />
            )}
        </Formik>
    );
};

export default AddVisit;
