import {
    AdminTypeV2,
    DispositionType,
    JobStatus,
    Pathway,
    Patient,
    PatientAlert,
} from '@doc-abode/data-models';
import { shouldBeSyncedToS1 } from '@doc-abode/helpers';
import { FormikValues } from 'formik';
import moment from 'moment';
import { VisitValuesType } from '../../components/pages/ucr/blocks/panels/VisitDetailsTypes';
import { IHcp } from '../../interfaces/ucr';
import filterPatientAlerts from './filterPatientAlerts';
import { getMomentEndTime } from './getEndDateTime';
import { GenderCompatibility, getGenderWarning, getLanguageWarning } from './helpers';
import { isMultiAssigneeJob } from './isMultiAssigneeJob';

export enum WarningMessages {
    PLANNED_START_IN_PAST = 'Planned start time of the job is in the past!',
    AFTER_LATEST_TIME = 'The planned time of visit is after to the latest time of visit',
    BEFORE_LASTEST_TIME = 'The planned date or time of visit is prior to the earliest time of visit',
    MISSING_HCP1 = 'First staff member is not assigned!',
    MISSING_HCP2 = 'Second staff member is not assigned!',
    MISSING_S1_REFERRAL = 'This job will be synced to SystmOne but is not associated with a referral.',
}

export const getCareStepWarnings = (
    values: FormikValues,
    pathways: Pathway[],
    adminTypes: AdminTypeV2[],
    isAdmin?: boolean,
): string[] | [] => {
    const stepWarnings = [];

    const visitDateTime = moment(values.visitDate).set({
        hour: Number(moment(values.startTime).hour()),
        minutes: Number(moment(values.startTime).minutes()),
        seconds: 0,
        milliseconds: 0,
    });

    // Converting to a Patient object to allow reuse of existing utilities
    const tempJob = {
        hcpId: values.hcpId,
        buddyId: values.buddyId,
        jobStatus: values.jobStatus,
        buddyJobStatus: values.buddyJobStatus,
        staffRequired: values.staffRequired,
        referralPathway: values.referralPathway,
        disposition: !isAdmin ? values.disposition : DispositionType.ADMIN,
        activityType: values.activityType,
        startDateTime: visitDateTime.toISOString(),
        systmOneRef: values.systmOneRef,
    } as Patient;

    const isDoubleUp = isMultiAssigneeJob(tempJob);

    // Job starting in the past is only relevant while it has not yet started
    if (hasPlannedStartTimeInThePast(tempJob)) {
        stepWarnings.push(WarningMessages.PLANNED_START_IN_PAST);
    }

    // Dbl-up warnings are subject to the state of the job
    const qualifyingStatesForPartiallyAssigned = [
        JobStatus.PENDING,
        JobStatus.ACCEPTED,
        JobStatus.CURRENT,
        JobStatus.ARRIVED,
        undefined,
    ];

    if (
        isDoubleUp &&
        !tempJob.buddyId &&
        qualifyingStatesForPartiallyAssigned.includes(tempJob.jobStatus)
    ) {
        stepWarnings.push(WarningMessages.MISSING_HCP2);
    }

    if (
        isDoubleUp &&
        !tempJob.hcpId &&
        qualifyingStatesForPartiallyAssigned.includes(tempJob.buddyJobStatus)
    ) {
        stepWarnings.push(WarningMessages.MISSING_HCP1);
    }

    // S1 warnings should always be shown
    // The pathway and admin should never be blank
    // but during live testing of the system the UI occasionally crashed due to undefined values
    if (hasS1ReferralWarning(tempJob, adminTypes || [], pathways || [])) {
        stepWarnings.push(WarningMessages.MISSING_S1_REFERRAL);
    }

    return stepWarnings;
};

export const checkLatestTimeWarning = (values: FormikValues): string[] | [] => {
    const timeFrameWarnings: string[] = [];
    const visitDateTime = moment(values.visitDate).set({
        hour: Number(moment(values.startTime).hour()),
        minutes: Number(moment(values.startTime).minutes()),
    });
    const latestHours = values?.availableTo ? moment(values.availableTo).hour() : '23';
    const latestMinutes = values?.availableTo ? moment(values.availableTo).minute() : '00';
    const latestTimeVsPlaned = visitDateTime
        ? moment(visitDateTime).set({
              hour: Number(latestHours),
              minute: Number(latestMinutes),
          })
        : null;

    const isPlannedAfterLatestTime =
        values.availableTo &&
        moment(getMomentEndTime(visitDateTime.toDate(), values.duration)).isAfter(
            latestTimeVsPlaned,
        );

    if (isPlannedAfterLatestTime) {
        timeFrameWarnings.push(WarningMessages.AFTER_LATEST_TIME);
    }
    return timeFrameWarnings;
};

export const getTimeFrameWarnings = (values: FormikValues): string[] | [] => {
    if (!values.availableFrom) return [];
    const timeFrameWarnings: string[] = [];
    const visitDateTime = moment(values.visitDate).set({
        hour: Number(moment(values.startTime).hour()),
        minutes: Number(moment(values.startTime).minutes()),
    });
    const visitDate = moment(values.visitDate).format('MM-DD-YYYY');
    const earliestHours = values?.availableFrom ? moment(values.availableFrom).hour() : '00';
    const earliestMinutes = values?.availableFrom ? moment(values.availableFrom).minute() : '00';
    const earliestDate = moment(values.earliestDateOfVisit).format('MM-DD-YYYY');
    const earliestTime = values.earliestDateOfVisit
        ? moment(earliestDate, 'MM-DD-YYYY').set({
              hour: Number(earliestHours),
              minute: Number(earliestMinutes),
              seconds: 0,
              milliseconds: 0,
          })
        : null;

    const visitTimeWithoutSeconds = moment(visitDate, 'MM-DD-YYYY').set({
        hour: visitDateTime.hour(),
        minute: visitDateTime.minute(),
        seconds: 0,
        milliseconds: 0,
    });

    const isBeforeEarliestTime =
        !!values.earliestDateOfVisit && visitTimeWithoutSeconds.isBefore(earliestTime);

    if (isBeforeEarliestTime) {
        timeFrameWarnings.push(WarningMessages.BEFORE_LASTEST_TIME);
    }
    return timeFrameWarnings;
};

export const getAllWarnings = (
    values: FormikValues,
    pathways: Pathway[],
    adminTypes: AdminTypeV2[],
    isAdmin: boolean = false,
): string[] => {
    const allWarnings = [];

    const isNotAborted =
        values.jobStatus !== JobStatus.HCP_ABORTED &&
        values.jobStatus !== JobStatus.CONTROLLER_ABORTED;

    if (isNotAborted && values.jobStatus !== JobStatus.COMPLETED) {
        allWarnings.push(...getTimeFrameWarnings(values));
    }
    if (isNotAborted) {
        allWarnings.push(...checkLatestTimeWarning(values));
    }

    // Step warnings are subject to status of the job, any state dependent logic needs to be pushed into lower level code
    allWarnings.push(...getCareStepWarnings(values, pathways, adminTypes, isAdmin));

    return allWarnings;
};

export function isJobInQualifyingStateForBreachOfTimeWarnings(jobStatus: JobStatus): boolean {
    return [JobStatus.PENDING, JobStatus.AVAILABLE, JobStatus.ACCEPTED, JobStatus.CURRENT].includes(
        jobStatus,
    );
}

export function isBreachOfLatestArrival(job: Patient): boolean {
    // If there is no time constraint, we cannot be in breach
    if (!job.availableTo) {
        return false;
    }

    // If the job is in certain states, we are not interested in breach of time warnings
    // Condition is incomplete, needs to account for dbl-up
    if (!isJobInQualifyingStateForBreachOfTimeWarnings(job.jobStatus)) {
        return false;
    }

    // If the calculated end time is after the latest time of arrival, we have a breach
    // Not using the endDateTime as the field is not reliably set; hence calculating startDateTime + duration
    if (moment(getMomentEndTime(job.startDateTime, job.duration)).isAfter(job.availableTo)) {
        return true;
    }

    // If the planned end time has passed and the job is not in progress (= qualifying states), we have a breach
    if (moment().isAfter(job.availableTo)) {
        return true;
    }

    return false;
}

export function isBreachOfEarliestArrival(job: Patient): boolean {
    // If there is no time constraint, we cannot be in breach
    if (!job.availableFrom) {
        return false;
    }

    // If the job is in certain states, we are not interested in breach of time warnings
    // Condition is incomplete, needs to account for dbl-up
    if (!isJobInQualifyingStateForBreachOfTimeWarnings(job.jobStatus)) {
        return false;
    }

    // If the planned startTime is prior to the earliest time of arrival, we have a breach
    if (moment(job.startDateTime).isBefore(job.availableFrom)) {
        return true;
    }

    return false;
}

const getWarningVisitData = (values: Patient): VisitValuesType =>
    ({
        id: values.id,
        nhsNumber: values.nhsNumber,
        startTime: values.startDateTime,
        visitDate: values.dateOfVisit,
        earliestDateOfVisit: values.earliestDateOfVisit,
        availableFrom: values.availableFrom,
        availableTo: values.availableTo,
        duration: values.duration,
        arrivedDateTime: values.arrivedDateTime,
        finishedDateTime: values.finishedDateTime,
        jobStatus: values.jobStatus,
        buddyJobStatus: values.buddyJobStatus,
        hcpId: values.hcpId,
        buddyId: values.buddyId,
        staffRequired: values.staffRequired,
        disposition: values.disposition,
        referralPathway: values.referralPathway,
        activityType: values.activityType,
        systmOneRef: values.systmOneRef,
    }) as any;
export function createStepWarnings(
    hcpUsers: any[],
    patient: Patient,
    pathways: Pathway[],
    adminTypes: AdminTypeV2[],
    isSecondHCP?: boolean,
) {
    const hcpArr: (any | void)[] = [
        hcpUsers.find((hcp) =>
            isSecondHCP ? hcp.userId === patient?.buddyId : hcp.userId === patient?.hcpId,
        ),
    ];

    const genderWarnings = hcpArr.flatMap((hcp) =>
        getGenderWarning(hcp, patient.staffPreferredGender as Array<keyof GenderCompatibility>),
    );
    const hcpLanguageWarnings = hcpArr.flatMap((hcp) =>
        getLanguageWarning(hcp, patient.languagesSpoken),
    );
    return [
        ...getAllWarnings(getWarningVisitData(patient), pathways, adminTypes),
        ...genderWarnings,
        ...hcpLanguageWarnings,
    ].filter(Boolean); // Just make sure there's no empties
}

export function hasUnresolvedPatientAlerts(job: Patient, patientAlerts: PatientAlert[]) {
    return filterPatientAlerts(patientAlerts, job.id).hasUnresolvedAlerts;
}

export function hasGenderWarning(hcp?: IHcp, genderPreferences?: string[]) {
    return getGenderWarning(hcp, genderPreferences as Array<keyof GenderCompatibility>).length > 0;
}

export function hasLanguageWarning(hcp?: IHcp, languagesSpoken?: string[]) {
    return getLanguageWarning(hcp, languagesSpoken).length > 0;
}

export function hasGeolocationError(job: Patient): boolean {
    // If there are no address details, the job can't have address warnings
    // Using the postcode as it is one of the mandatory fields
    if (!job.postCode) {
        return false;
    }

    // If we have lat/long data, it's not an error
    if (job.latitude && job.longitude) {
        return false;
    }

    return true;
}

export function hasCarRequiredWarning(job: Patient): boolean {
    return job.carRequired || false;
}

export function hasComplexCare(job: Patient): boolean {
    return job.careComplexity === 'complex';
}

export function hasPlannedStartTimeInThePast(job: Patient): boolean {
    const qualifyingJobStatus = [JobStatus.PENDING, JobStatus.ACCEPTED, undefined];

    // If there is no planned start time, the job cannot be in the past
    if (!job.startDateTime) {
        return false;
    }

    // If the current time prior to the planned start time, the job is not in the past
    if (moment().diff(job.startDateTime) < 0) {
        return false;
    }

    // Otherwise we need to check the status of the job, incl. for dbl-ups
    if (qualifyingJobStatus.includes(job.jobStatus)) {
        return true;
    }

    if (isMultiAssigneeJob(job) && qualifyingJobStatus.includes(job.buddyJobStatus)) {
        return true;
    }

    // Any other condition means the job is not in the past
    return false;
}

/**
 * function expects the caller to have checked whether S1 is enabled
 * @param job
 * @param adminTypes
 * @param pathways
 * @returns
 */
export function hasS1ReferralWarning(job: Patient, adminTypes: AdminTypeV2[], pathways: Pathway[]) {
    const shouldBeSynced = shouldBeSyncedToS1(job, pathways, adminTypes);

    if (!shouldBeSynced) {
        return false;
    }

    return !job.systmOneRef;
}
