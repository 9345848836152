import { DateInput3 } from '@blueprintjs/datetime2';
import cn from 'classnames';
import moment from 'moment';
import { FC, useMemo, useState } from 'react';
import { Button, ButtonColors } from '../Button';

import { getMomentDateFormatter } from '../../../../helpers/ucr';
import { IconNavigateNext, IconNavigatePrev } from '../../../../helpers/ucr/icons';
import useStores from '../../../../hook/useStores';
import { defaultDateFormat } from './helpers';

const ONE_YEAR = 31536000000;

interface IProps {
    name: string;
    className?: string;
    dateFormat?: string;
    showTodayButton?: boolean;
    onChange: (date: Date) => void;
}

const DatePrevNext: FC<IProps> = ({
    name,
    className,
    dateFormat = defaultDateFormat,
    showTodayButton,
    onChange,
}) => {
    const {
        RootStore: {
            ucrStore: { setBottomPanelOpen, selectedDate },
        },
    } = useStores();

    const [date, setDate] = useState<Date>(moment.utc(selectedDate || new Date()).toDate());
    const dateInputProps = useMemo(() => getMomentDateFormatter(dateFormat), [dateFormat]);

    const changeDateEvent = (dateString: string | null): void => {
        if (!dateString) {
            return;
        }
        const date = new Date(dateString);
        setDate(date);
        onChange(date);
        setBottomPanelOpen(false);
    };

    const clickPrevEvent = (): void => {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() - 1);

        onChange(newDate);
        setDate(newDate);
        setBottomPanelOpen(false);
    };

    const clickNextEvent = (): void => {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + 1);

        onChange(newDate);
        setDate(newDate);
        setBottomPanelOpen(false);
    };

    const clickTodayEvent = (): void => {
        const newDate = moment.utc(new Date()).toDate();

        onChange(new Date());
        setDate(newDate);
        setBottomPanelOpen(false);
    };

    return (
        <div className={cn('v2__date-prev-next', className)}>
            {showTodayButton ? (
                <Button
                    className="v2__date-prev-next-today"
                    name="Today"
                    color={ButtonColors.GREY}
                    clickEvent={clickTodayEvent}
                />
            ) : undefined}
            <Button
                className="v2__date-prev-next-button"
                color={ButtonColors.GREY}
                Icon={IconNavigatePrev}
                clickEvent={clickPrevEvent}
            />
            <div className="v2__date-prev-next-fields">
                <DateInput3
                    locale={navigator.languages[0]}
                    showTimezoneSelect={false}
                    {...dateInputProps}
                    value={date.toISOString()}
                    inputProps={{
                        id: name,
                        name: name,
                        className: 'v2__date-prev-next-input',
                    }}
                    popoverProps={{
                        className: 'v2__date-prev-next-popover',
                    }}
                    dayPickerProps={{
                        className: 'v2__date-prev-next-day-picker',
                    }}
                    onChange={changeDateEvent}
                    canClearSelection={false}
                    maxDate={new Date(Date.now() + ONE_YEAR * 3)}
                    className="v2__date-prev-next-date-input"
                />
            </div>
            <Button
                className="v2__date-prev-next-button"
                color={ButtonColors.GREY}
                Icon={IconNavigateNext}
                clickEvent={clickNextEvent}
            />
        </div>
    );
};

export default DatePrevNext;
