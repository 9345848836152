import { ApolloError } from '@apollo/client';
import { Alert, Dialog } from '@blueprintjs/core';
import { Form, Formik, FormikContextType, FormikValues, useFormikContext } from 'formik';
import { FC, useCallback, useState } from 'react';

import { JobStatus } from '@doc-abode/data-models';
import {
    getChangeConfirmationDialogMessage,
    isQualifyingStateForChangeConfirmationDialog,
} from '../../../../../helpers/ucr/changeConfirmationDialogHelper';
import useStores from '../../../../../hook/useStores';
import RootStore from '../../../../../stores/RootStore';
import SystmOneCommunityParser from '../../../../modules/helpers/SystmOneCommunityParser';
import AppToaster from '../../../../modules/helpers/Toaster';
import {
    Accordion,
    AccordionColors,
    AccordionTab,
    Button,
    ButtonElems,
    ButtonSizes,
} from '../../../../v2/components';
import { TextArea } from '../../../../v2/form';
import CareDetails from '../AddVisit/CareDetails';
import ReferralDetails from '../AddVisit/ReferralDetails';
import { getStepSchema } from '../AddVisit/validation';
import { PatientDetails } from '../PatientDetails';
import { FormMode, FormSteps } from '../common';

interface IIsConfirmationDialogNeededInput {
    jobStatus?: JobStatus;
    buddyJobStatus?: JobStatus;
}

interface IIsConfirmationDialogNeededOutput {
    qualifiesForChangeConfirmationDialog: boolean;
    changeConfirmationMessage: string;
}

export function isConfirmationDialogNeeded({
    jobStatus,
    buddyJobStatus,
}: IIsConfirmationDialogNeededInput): IIsConfirmationDialogNeededOutput {
    // Need to check whether any part is in a state that requires a confirmation dialog
    const hcp1InQualifyingStateForChangeConfirmationDialog =
        isQualifyingStateForChangeConfirmationDialog(jobStatus);
    const hcp2InQualifyingStateForChangeConfirmationDialog =
        isQualifyingStateForChangeConfirmationDialog(buddyJobStatus);

    let changeConfirmationMessage = '';
    // If both parts are in a qualifying state, we arbitrarily show a message for the 1st HCP
    if (hcp1InQualifyingStateForChangeConfirmationDialog) {
        changeConfirmationMessage = getChangeConfirmationDialogMessage(jobStatus);
    } else if (hcp2InQualifyingStateForChangeConfirmationDialog) {
        changeConfirmationMessage = getChangeConfirmationDialogMessage(buddyJobStatus);
    }

    return {
        qualifiesForChangeConfirmationDialog:
            hcp1InQualifyingStateForChangeConfirmationDialog ||
            hcp2InQualifyingStateForChangeConfirmationDialog,
        changeConfirmationMessage,
    };
}

export interface IProps {
    step: FormSteps;
    loading: boolean;
    formMode: FormMode;
    error?: ApolloError;
    onSave: (values: FormikValues) => Promise<void>;
}

const EditVisitForm: FC<IProps> = ({ step, loading, formMode, onSave }) => {
    const [showPullFromReferral, setShowPullFromReferral] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [patientData, setPatientData] = useState(false);

    const {
        values,
        setFieldValue,
        isValid,
        setTouched,
        setValues,
    }: FormikContextType<FormikValues> = useFormikContext();

    const {
        RootStore: {
            lovsStore: { languagesSpoken, gender, genderStaffPreferred },
        },
    } = useStores<{ RootStore: RootStore }>();

    // Need to check whether any part is in a state that requires a confirmation dialog
    const { qualifiesForChangeConfirmationDialog, changeConfirmationMessage } =
        isConfirmationDialogNeeded({
            jobStatus: values.jobStatus,
            buddyJobStatus: values.buddyJobStatus,
        });

    const onSaveForm = useCallback(
        (curStep: FormSteps) => {
            const stepSchema = getStepSchema(curStep);
            const stepFields = Object.keys(stepSchema.fields).reduce(
                (fields, field) => ({ ...fields, [field]: true }),
                {},
            );

            setTouched(stepFields, false);

            if (isValid) {
                // For a dbl-up we need to check both parts

                if (qualifiesForChangeConfirmationDialog) {
                    setShowAlert(true);
                } else {
                    onSave(values);
                }
            }
        },
        [setTouched, isValid, qualifiesForChangeConfirmationDialog, onSave, values],
    );

    const onPullFromReferral = ({
        patientSummary,
        referralDetails,
    }: {
        patientSummary: string;
        referralDetails: string;
    }) => {
        try {
            const parsedValues = SystmOneCommunityParser(patientSummary, referralDetails);
            const parsedFields = Object.keys(parsedValues);
            if (parsedFields.length > 0) {
                setValues({ ...values, ...parsedValues }, false);
                setTouched(
                    parsedFields.reduce((fields, field) => ({ ...fields, [field]: true }), {}),
                );
                setShowPullFromReferral(false);
                AppToaster.show({
                    message: `Successfully parsed ${parsedFields.length} fields!`,
                    intent: 'success',
                });
            } else {
                AppToaster.show({
                    message: 'No fields were parsed from the supplied data',
                    intent: 'warning',
                });
            }
        } catch (err: any) {
            console.error('Error parsing referral data', err.message);
            AppToaster.show({
                message: 'Unable to parse patient referral',
                intent: 'danger',
            });
        }
    };

    const saveVisitingForm = () => {
        setShowAlert(false);
        onSave(values);
    };

    const closeAlert = () => {
        setShowAlert(false);
    };

    return (
        <Form>
            <Accordion>
                <AccordionTab
                    name="patients"
                    title="Patient details"
                    color={step === FormSteps.PATIENT ? AccordionColors.PINK : AccordionColors.GREY}
                    open={step === FormSteps.PATIENT}
                    disabled={step !== FormSteps.PATIENT}
                >
                    <PatientDetails
                        values={values}
                        loading={loading}
                        formMode={formMode}
                        languagesSpoken={languagesSpoken}
                        gender={gender}
                        genderStaffPreferred={genderStaffPreferred}
                        onSaveForm={onSaveForm}
                        usePatientData={patientData}
                        setPatientData={() => setPatientData(false)}
                    />
                </AccordionTab>
                <AccordionTab
                    name="referral"
                    title="Referral details"
                    color={
                        step === FormSteps.REFERRAL ? AccordionColors.PINK : AccordionColors.GREY
                    }
                    open={step === FormSteps.REFERRAL}
                    disabled={step !== FormSteps.REFERRAL}
                >
                    <ReferralDetails
                        values={values}
                        setFieldValue={setFieldValue}
                        loading={loading}
                        formMode={formMode}
                        onSaveForm={onSaveForm}
                    />
                </AccordionTab>
                <AccordionTab
                    name="care"
                    title="Care details"
                    color={step === FormSteps.CARE ? AccordionColors.PINK : AccordionColors.GREY}
                    open={step === FormSteps.CARE}
                    disabled={step !== FormSteps.CARE}
                >
                    <CareDetails
                        values={values}
                        loading={loading}
                        formMode={formMode}
                        onSaveForm={onSaveForm}
                        setFieldValue={setFieldValue}
                    />
                </AccordionTab>
            </Accordion>
            <Dialog
                isOpen={showPullFromReferral}
                onClose={() => setShowPullFromReferral(false)}
                title="Pull from referral"
            >
                <Formik
                    initialValues={{
                        patientSummary: '',
                        referralDetails: '',
                    }}
                    onSubmit={onPullFromReferral}
                >
                    <Form>
                        <div className="v2__form-block">
                            <TextArea
                                label="Patient summary"
                                name="patientSummary"
                                className="v2__form-group--pos-1-1"
                            />
                            <TextArea
                                label="Referral details"
                                name="referralDetails"
                                className="v2__form-group--pos-1-1"
                            />
                            <label className="v2__form-group">
                                <Button
                                    name="Import"
                                    elem={ButtonElems.BUTTON}
                                    size={ButtonSizes.MEDIUM}
                                    className="v2__form-submit-button"
                                    type="submit"
                                />
                            </label>
                        </div>
                    </Form>
                </Formik>
            </Dialog>
            <Alert
                isOpen={showAlert}
                onConfirm={saveVisitingForm}
                onCancel={closeAlert}
                cancelButtonText="Cancel"
                confirmButtonText="Yes"
                icon="warning-sign"
                intent="danger"
            >
                <p>{changeConfirmationMessage}</p>
            </Alert>
        </Form>
    );
};

export default EditVisitForm;
