import { FC } from 'react';
import { DataDetails, IDetailsRow } from '../../../../../v2/components/DataDetails';
import { IHeader, JobSummaryHCP, JobSummaryHeader } from './JobSummaryHeader';
import { JobSummaryWarning, JobSummaryWarnings } from './JobSummaryWarning';

import { WarningMessages } from '../../../../../../helpers/ucr/getWarnings';

import sortBy from 'lodash/sortBy';

import { Patient } from '@doc-abode/data-models';
import {
    Activity,
    EarliestArrival,
    Event,
    Forward,
    Hand,
    Home,
    IconCalendar,
    IconCarRequired,
    IconComplexCare,
    IconNonEnglishSpeaker,
    LatestArrival,
    Pathway,
    Person,
    Sex,
    Sms,
} from '../../../../../../helpers/ucr/icons';

export enum JobType {
    ADMIN = 'admin',
    VISIT = 'visit',
}

interface IJobSummaryData {
    // Patient Details
    address?: string;
    dateOfBirth?: string;
    gender?: string;

    // Activity & Activity Details
    abortedReason?: string;
    abortedNotes?: string;
    referrer?: string;
    pathway?: string;
    service?: string;
    activity?: string;

    // Notes
    preVisitNotes?: string;

    // Warning Icons
    isNonEnglishSpoken?: boolean;
    isCareComplex?: boolean;
    isCarRequired?: boolean;
    availableFrom?: string;
    availableTo?: string;
}

export interface IJobSummaryComponentProps {
    type: JobType;

    header: IHeader;

    data: IJobSummaryData;

    warnings: JobSummaryWarning[];

    patient: Patient;
}

const patientDetailsRows: IDetailsRow<IJobSummaryData>[] = [
    { key: 'address', Icon: Home },
    { key: 'dateOfBirth', Icon: IconCalendar },
    { key: 'gender', Icon: Sex },
];
const visitDetailsRows: IDetailsRow<IJobSummaryData>[] = [
    { key: 'referrer', Icon: Forward },
    { key: 'pathway', Icon: Pathway },
    { key: 'service', Icon: Hand },
];
const activityDetailsRows: IDetailsRow<IJobSummaryData>[] = [
    { key: 'abortedReason', Icon: Person },
    { key: 'abortedNotes', Icon: Person },
    { key: 'activity', Icon: Activity },
];
const preVisitDetailsRows: IDetailsRow<IJobSummaryData>[] = [{ key: 'preVisitNotes', Icon: Sms }];
const postVisitDetailsRows: IDetailsRow<IJobSummaryData>[] = [
    { key: 'abortedReason', Icon: Event, urgent: true },
    { key: 'abortedNotes', Icon: Sms, urgent: true },
];

type IGetWarningsRows = (
    isVisitEarliestTimeBreach: boolean,
    isVisitLatestTimeBreach: boolean,
    keyArray: string[],
    iconsArray: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>[],
) => IDetailsRow<Record<string | number | symbol, unknown>>[];

const getWarningsRows: IGetWarningsRows = (
    isVisitEarliestTimeBreach,
    isVisitLatestTimeBreach,
    keyArray,
    iconsArray,
) => [
    { key: 'availableFrom', Icon: EarliestArrival, urgent: isVisitEarliestTimeBreach },
    { key: 'availableTo', Icon: LatestArrival, urgent: isVisitLatestTimeBreach },
    {
        multipleKey: keyArray,
        multipleIcon: iconsArray,
    },
];

export const JobSummaryComponent: FC<IJobSummaryComponentProps> = ({
    type,
    header,
    warnings,
    data,
    patient,
}) => {
    const sortedHcp = header.hcps?.length
        ? sortBy(header.hcps, (hcp) => hcp?.hcpID !== header.currentHcp)
        : null;

    const filteredData = [];
    const filteredIcons = [];

    for (const [key, value] of Object.entries(data)) {
        if (key === 'isNonEnglishSpoken' && value) {
            filteredData.push(key);
            filteredIcons.push(IconNonEnglishSpeaker);
        } else if (key === 'isCarRequired' && value) {
            filteredData.push(key);
            filteredIcons.push(IconCarRequired);
        } else if (key === 'isCareComplex' && value) {
            filteredData.push(key);
            filteredIcons.push(IconComplexCare);
        }
    }

    const isVisitEarliestTimeBreach = warnings.find(
        (w) => w.content === WarningMessages.BEFORE_LASTEST_TIME,
    );
    const isVisitLatestTimeBreach = warnings.find(
        (w) => w.content === WarningMessages.AFTER_LATEST_TIME,
    );
    const warningsRows = getWarningsRows(
        Boolean(isVisitEarliestTimeBreach),
        Boolean(isVisitLatestTimeBreach),
        filteredData,
        filteredIcons,
    );

    return (
        <div className={`job-summary job-summary--${type}`}>
            <JobSummaryHeader header={header} patient={patient} />
            <JobSummaryWarnings warnings={warnings} />
            {sortedHcp && (
                <div>
                    {sortedHcp.map((hcp) => (
                        <JobSummaryHCP key={hcp?.hcpID} hcp={hcp} currentHcp={header.currentHcp} />
                    ))}
                </div>
            )}
            <DataDetails
                rows={patientDetailsRows}
                data={data}
                classes="job-summary__section job-summary__section--solid"
            />
            {type === JobType.VISIT && (
                <DataDetails rows={visitDetailsRows} data={data} classes="job-summary__section" />
            )}

            {type === JobType.ADMIN && (
                <DataDetails
                    rows={activityDetailsRows}
                    data={data}
                    classes="job-summary__section"
                    isAborted={!!data.abortedReason}
                />
            )}

            <DataDetails rows={warningsRows} data={data} classes="job-summary__section " />

            <DataDetails rows={preVisitDetailsRows} data={data} classes="job-summary__section" />
            <DataDetails
                rows={postVisitDetailsRows}
                data={data}
                classes="job-summary__section job-summary__section--no-border"
            />
        </div>
    );
};
