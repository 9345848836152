interface MarkerPropsSingle {
    fill: string;
    label: string;
    foundHcp?: boolean;
    hcpPosition?: number;
}

interface MarkerPropsDoubleUp extends MarkerPropsSingle {
    fillTwo: string;
    label2: string;
    foundBuddy?: boolean;
    buddyPosition?: number;
}

export const Markers: any = {
    singleHcp: (args: MarkerPropsSingle) => {
        const { fill, label } = args;

        return `<svg id="Visit" xmlns="http://www.w3.org/2000/svg" width="40" height="50" viewBox="0 0 40 50">
            <path id="tip" data-name="Tip" d="M19.994 49.187c-.388 0-.704-.088-.704-.194v-9.155c0-.107.316-.194.704-.194.389 0 .705.087.705.194v9.155c0 .106-.316.194-.705.194Z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(102, 103, 107); fill-rule: nonzero; opacity: 1;"/>
            <rect id="outer" data-name="Outer" x="-51.036" y="-43.479" width="30" height="30" rx="5" ry="5" fill="#${fill}" transform="rotate(45 -67.775 63.907)"/>
            <rect id="inner" data-name="Inner" x="-37.754" y="-32.164" width="22" height="22" rx="5" ry="5" fill="none" transform="rotate(45 -54.141 56.293)" stroke="#fff"/>
            <text fill="#fff" font-size="10" font-family="Mulish, sans-serif" font-weight="700" dominant-baseline="middle" text-anchor="middle"><tspan x="19.724" y="22.584">${label}</tspan></text>
        </svg>`;
    },

    single: (args: MarkerPropsSingle) => {
        const { fill, label, hcpPosition, foundHcp } = args;

        return `<svg id="Visit" xmlns="http://www.w3.org/2000/svg" width="32" height="40" viewBox="0 0 32 40">
                <path id="Path_2679" data-name="Path 2679" d="M24,44A66.476,66.476,0,0,1,11.975,31.275Q8,25.4,8,20.4q0-7.5,4.825-11.95a16.257,16.257,0,0,1,22.35,0Q40,12.9,40,20.4q0,5-3.975,10.875A66.476,66.476,0,0,1,24,44Z" transform="translate(-8 -4)" fill="#${fill}"/>
                <path id="Path_2680" data-name="Path 2680" d="M24,31a10.6,10.6,0,0,0,7.778-3.221A10.6,10.6,0,0,0,35,20a10.6,10.6,0,0,0-3.221-7.778A10.6,10.6,0,0,0,24,9a10.6,10.6,0,0,0-7.778,3.221A10.6,10.6,0,0,0,13,20a10.6,10.6,0,0,0,3.221,7.778A10.6,10.6,0,0,0,24,31Z" transform="translate(-7.999 -3.999)" fill="none" stroke="#fff" stroke-width="1"/>
                <text id="CE" transform="translate(0 9)" fill="#fff" font-size="10" font-family="Mulish, sans-serif" font-weight="700" dominant-baseline="middle" text-anchor="middle" ><tspan x="16" y="8">${label}</tspan></text>
                <path opacity="${
                    !foundHcp || !hcpPosition ? 0 : 100
                }" id="Path_2700" data-name="Path 2700" d="M19.077,21.154a6.075,6.075,0,0,0,6.077-6.077A6.075,6.075,0,0,0,19.077,9,6.075,6.075,0,0,0,13,15.077a6.075,6.075,0,0,0,6.077,6.077Z" transform="translate(-3 14.0)" fill="#546e7a" stroke="#fff" stroke-width="1"/>
                <text opacity="${
                    !foundHcp || !hcpPosition ? 0 : 100
                }" id="_2" data-name="2" dominant-baseline="middle" text-anchor="middle" transform="translate(16 30.000)" fill="#fff" font-size="7" font-family="Mulish, sans-serif" font-weight="700">
                    <tspan x="0" y="0">${hcpPosition}</tspan>
                </text>
        </svg>`;
    },
    singleActive: (args: MarkerPropsSingle) => {
        const { fill, label, foundHcp, hcpPosition } = args;
        return `<svg id="HCP" xmlns="http://www.w3.org/2000/svg" width="40" height="50" viewBox="0 0 40 50">
            <path id="Path_2677" data-name="Path 2677" d="M28,54Q17.937,45.437,12.969,38.094T8,24.5q0-9.375,6.031-14.937a20.321,20.321,0,0,1,27.937,0Q48,15.126,48,24.5q0,6.249-4.969,13.594T28,54Z" transform="translate(-8 -4)" fill="#${fill}"/>
            <path id="Path_2678" data-name="Path 2678" d="M26.589,36.178A13.1,13.1,0,0,0,36.2,32.2a13.1,13.1,0,0,0,3.979-9.61,13.1,13.1,0,0,0-3.979-9.61A13.1,13.1,0,0,0,26.589,9a13.1,13.1,0,0,0-9.61,3.979A13.1,13.1,0,0,0,13,22.589a13.1,13.1,0,0,0,3.979,9.61A13.1,13.1,0,0,0,26.589,36.178Z" transform="translate(-6.589 -2.589)" fill="#fff"/>
            <text id="CE" transform="translate(1.6 13.5)" fill="#${fill}" font-size="12" font-family="Mulish, sans-serif" font-weight="700" dominant-baseline="middle" text-anchor="middle" ><tspan x="20" y="8"> ${
                label || ''
            } </tspan></text>
        <path opacity="${
            !foundHcp || !hcpPosition ? 0 : 100
        }" id="Path_2700" data-name="Path 2700" d="M19.077,21.154a6.075,6.075,0,0,0,6.077-6.077A6.075,6.075,0,0,0,19.077,9,6.075,6.075,0,0,0,13,15.077a6.075,6.075,0,0,0,6.077,6.077Z" transform="translate(1 23.0)" fill="#${fill}" stroke="#fff" stroke-width="1"/>
        <text opacity="${
            !foundHcp || !hcpPosition ? 0 : 100
        }" id="_2" data-name="2" dominant-baseline="middle" text-anchor="middle" transform="translate(20 39.000)" fill="#fff" font-size="7" font-family="Mulish, sans-serif" font-weight="700">
            <tspan x="0" y="0">${hcpPosition}</tspan>
        </text>
        </svg>`;
    },
    double: (args: MarkerPropsDoubleUp) => {
        const { fill, fillTwo, label, label2, foundHcp, hcpPosition, foundBuddy, buddyPosition } =
            args;

        return `<svg id="Double_Up" data-name="Double Up" xmlns="http://www.w3.org/2000/svg" width="32" height="54" viewBox="0 0 32 54">
        <defs>
            <linearGradient id="grad1" x1="0.5" y1="0" x2="0.5" y2="1">
            <stop offset="0%" stop-color="#${fill}"/>
            <stop offset="50%" stop-color="#${fill}"/>
            <stop offset="50%" stop-color="#${fillTwo}"/>
            <stop offset="100%" stop-color="#${fillTwo}"/>
            </linearGradient>
        </defs>
        <path id="Path_2681" data-name="Path 2681" d="M276.42,393.945a16,16,0,0,0-16,16v14.4q0,5,3.975,10.875a66.455,66.455,0,0,0,12.025,12.725,66.516,66.516,0,0,0,12.025-12.725q3.975-5.875,3.975-10.875v-14.4A16,16,0,0,0,276.42,393.945Z" transform="translate(-260.42 -393.945)" fill="url(#grad1)" />
        <text id="BB" transform="translate(0 23)" fill="#fff" font-size="10" font-family="Mulish, sans-serif" font-weight="700" dominant-baseline="middle" text-anchor="middle" ><tspan x="16" y="10">${label2}</tspan></text>
        <text id="AA" transform="translate(0 10)" fill="#fff" font-size="10" font-family="Mulish, sans-serif" font-weight="700" dominant-baseline="middle" text-anchor="middle" ><tspan x="16" y="8">${label}</tspan></text>
        <rect id="Rectangle_2820" data-name="Rectangle 2820" width="22" height="36" rx="11" transform="translate(5 5)" fill="none" stroke="#fff" stroke-width="1"/>
        <path opacity="${
            !foundHcp || !hcpPosition ? 0 : 100
        }" id="Path_2700" data-name="Path 2700" d="M19.077,21.154a6.075,6.075,0,0,0,6.077-6.077A6.075,6.075,0,0,0,19.077,9,6.075,6.075,0,0,0,13,15.077a6.075,6.075,0,0,0,6.077,6.077Z" transform="translate(-3.077 -10.0)" fill="#546e7a" stroke="#fff" stroke-width="1"/>
        <text opacity="${
            !foundHcp || !hcpPosition ? 0 : 100
        }" id="_2" data-name="2" dominant-baseline="middle" text-anchor="middle" transform="translate(16 5.000)" fill="#fff" font-size="7" font-family="Mulish, sans-serif" font-weight="700">
            <tspan x="0" y="0">${hcpPosition}</tspan>
        </text> 
        <path opacity="${
            !foundBuddy || !buddyPosition ? 0 : 100
        }" id="Path_2700" data-name="Path 2700" d="M19.077,21.154a6.075,6.075,0,0,0,6.077-6.077A6.075,6.075,0,0,0,19.077,9,6.075,6.075,0,0,0,13,15.077a6.075,6.075,0,0,0,6.077,6.077Z" transform="translate(-3.077 30.0)" fill="#546e7a" stroke="#fff" stroke-width="1"/>
        <text opacity="${
            !foundBuddy || !buddyPosition ? 0 : 100
        }" id="_2" data-name="2" dominant-baseline="middle" text-anchor="middle" transform="translate(16 46.000)" fill="#fff" font-size="7" font-family="Mulish, sans-serif" font-weight="700">
            <tspan x="0" y="0">${buddyPosition}</tspan>
        </text>
      </svg>`;
    },
    doubleActive: (args: MarkerPropsDoubleUp) => {
        const { fill, fillTwo, label, label2, foundHcp, hcpPosition, foundBuddy, buddyPosition } =
            args;

        return `<svg xmlns="http://www.w3.org/2000/svg" width="40" height="64.865" viewBox="0 0 40 64.865">
            <g id="Group_3263" data-name="Group 3263" transform="translate(-1292 -175.301)">
                <g id="Group_3220" data-name="Group 3220" transform="translate(1292 175.301)">
                    <path id="Path_2689" data-name="Path 2689" d="M414.408,397.31v7.471q0,6.2,4.973,13.494a82.71,82.71,0,0,0,15.027,15.789,82.873,82.873,0,0,0,15.027-15.789q4.972-7.291,4.973-13.494V397.31Z" transform="translate(-414.408 -369.2)" fill="#${fillTwo}"/>
                    <path id="Path_2690" data-name="Path 2690" d="M454.408,375.161a19.926,19.926,0,0,0-20-19.853h0a19.926,19.926,0,0,0-20,19.853h0v9.257h40Z" transform="translate(-414.408 -355.308)" fill="#${fill}"/>
                    <rect id="Rectangle_2821" data-name="Rectangle 2821" width="27.027" height="43.243" rx="13.514" transform="translate(6.486 6.486)" fill="#fff"/>
                    <text id="AK" transform="translate(0 28.0)" fill="#${fillTwo}" font-size="12" font-family="Mulish, sans-serif" font-weight="700" dominant-baseline="middle" text-anchor="middle" ><tspan x="20" y="12">${label2}</tspan></text>
                    <text id="DS" transform="translate(0 14.0)" fill="#${fill}" font-size="12" font-family="Mulish, sans-serif" font-weight="700" dominant-baseline="middle" text-anchor="middle" ><tspan x="20" y="8">${label}</tspan></text>
                    <path opacity="${
                        !foundHcp || !hcpPosition ? 0 : 100
                    }" id="Path_2700" data-name="Path 2700" d="M19.077,21.154a6.075,6.075,0,0,0,6.077-6.077A6.075,6.075,0,0,0,19.077,9,6.075,6.075,0,0,0,13,15.077a6.075,6.075,0,0,0,6.077,6.077Z" transform="translate(1 -6.0)" fill="#${fill}" stroke="#fff" stroke-width="1"/>
                    <text opacity="${
                        !foundHcp || !hcpPosition ? 0 : 100
                    }" id="_2" data-name="2" dominant-baseline="middle" text-anchor="middle" transform="translate(19 10.000)" fill="#fff" font-size="7" font-family="Mulish, sans-serif" font-weight="700">
                        <tspan x="1" y="0">${hcpPosition}</tspan>
                    </text>
                    <path opacity="${
                        !foundBuddy || !buddyPosition ? 0 : 100
                    }" id="Path_2700" data-name="Path 2700" d="M19.077,21.154a6.075,6.075,0,0,0,6.077-6.077A6.075,6.075,0,0,0,19.077,9,6.075,6.075,0,0,0,13,15.077a6.075,6.075,0,0,0,6.077,6.077Z" transform="translate(1 37.0)" fill="#${fillTwo}" stroke="#fff" stroke-width="1"/>
                    <text opacity="${
                        !foundBuddy || !buddyPosition ? 0 : 100
                    }" id="_2" data-name="2" dominant-baseline="middle" text-anchor="middle" transform="translate(19 53.000)" fill="#fff" font-size="7" font-family="Mulish, sans-serif" font-weight="700">
                        <tspan x="1" y="0">${buddyPosition}</tspan>
                    </text>
                </g>
            </g>
        </svg>`;
    },
    cluster: (args: MarkerPropsSingle) => {
        const { fill, label } = args;
        return `<svg id="Multiple_Visits" data-name="Multiple Visits" xmlns="http://www.w3.org/2000/svg" width="32" height="40" viewBox="0 0 32 40">
                <path id="Path_2682" data-name="Path 2682" d="M24,44A66.476,66.476,0,0,1,11.975,31.275Q8,25.4,8,20.4q0-7.5,4.825-11.95a16.257,16.257,0,0,1,22.35,0Q40,12.9,40,20.4q0,5-3.975,10.875A66.476,66.476,0,0,1,24,44Z" transform="translate(-8 -4)" fill="#${fill}"/>
                <text id="DS" transform="translate(0 0)" fill="#ffffff" font-size="12" font-family="Mulish, sans-serif" font-weight="bold" dominant-baseline="middle" text-anchor="middle" ><tspan x="16" y="20">${label}</tspan></text>
                </svg>`;
    },
};
