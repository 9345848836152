import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink, from } from '@apollo/client';
import { ApolloProvider } from '@apollo/client/react';
import { hasDirectives, removeDirectivesFromDocument } from '@apollo/client/utilities';
import { when } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import Header from '../header/Header';

import Loader from '../../modules/helpers/Loader';
import PageNotFound from '../../pages/PageNotFound';

import { getBearerToken } from '../../../api/amplifyApi';
import { graphqlUrl, graphqlV2Url } from '../../../api/baseApi';
import { getDirectiveArgumentValueFromOperation } from '../../../graphql/helper';
import { getHomepageTab } from '../header/getHomepageTab';
import { getRoutes } from './getRoutes';
import { SentryRoute } from '../../../helpers/sentryRoute';

const createApolloClient = (organisation, httpLink) => {
    const apiVersionLink = new ApolloLink(async (operation, forward) => {
        let tokenType = 'access';

        if (hasDirectives(['api'], operation.query)) {
            let apiVersion = getDirectiveArgumentValueFromOperation(operation, 'api', 'version');
            const query = removeDirectivesFromDocument(
                [{ name: 'api', remove: true }],
                operation.query,
            );
            operation.query = query;
            operation.setContext({
                uri: apiVersion === 'v2' ? graphqlV2Url : graphqlUrl,
            });
            if (apiVersion === 'v2') {
                tokenType = 'id';
            }
        }

        const Authorization = await getBearerToken({ tokenType });

        operation.setContext({
            headers: {
                ...operation.getContext().headers,
                Authorization,
                'x-docabode-organisation': organisation,
            },
        });

        return forward(operation);
    });

    return new ApolloClient({
        link: from([apiVersionLink, httpLink]),
        cache: new InMemoryCache(),
        defaultOptions: {
            query: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'all',
            },
        },
    });
};

// List is populated based on user permissions when the component mounts
let routes = [];

const Router = ({ homePage }) => {
    return (
        <div className="controller">
            <SentryRoute path="/:section" component={Header} />
            <Switch>
                <Redirect exact from="/" to={homePage} />
                <Redirect from="/ucr" to="/scheduling" />
                {routes.map(({ path, component, exact }) => (
                    <SentryRoute key={path} path={path} component={component} exact={exact} />
                ))}
                <SentryRoute component={PageNotFound} />
            </Switch>
        </div>
    );
};

const ControllerMain = inject('RootStore')(
    observer(
        class ControllerMain extends Component {
            state = {
                apolloClient: null,
            };

            componentDidMount() {
                const { configStore, ucrStore, schedulesStore, userStore } = this.props.RootStore;

                when(
                    () => configStore.loaded,
                    () => {
                        // Get the list of all permitted routes
                        routes = getRoutes(
                            userStore.isController,
                            userStore.isAdmin,
                            userStore.isSuperuser,
                            userStore.isComplianceOfficer,
                            configStore.isFeatureEnabled,
                        );

                        if (
                            configStore.isFeatureEnabled('vaccinations') ||
                            configStore.isFeatureEnabled('ucr')
                        ) {
                            const httpLink = createHttpLink({
                                uri: graphqlUrl,
                            });

                            const apolloClient = createApolloClient(configStore.org, httpLink);

                            this.setState({ apolloClient });
                        }

                        // TO BE LOOKED AT
                        // startTimer very misleadingly does not just start a timer but also triggers the initial load of data for each of the modules
                        // This was not an issue while we had dedicated layouts for Admin and Controller portal, however with a single application layout has become an issue
                        // Short-term solution to stop this loading of data if the user is only an administrator
                        if (userStore.isController) {
                            if (configStore.isFeatureEnabled('ucr')) {
                                ucrStore.startTimer();
                                schedulesStore.startTimer();
                            }
                        }
                    },
                );
            }

            componentWillUnmount() {
                const {
                    usersStore,
                    jobsStore,
                    vaccinationsStore,
                    routesStore,
                    ucrStore,
                    schedulesStore,
                } = this.props.RootStore;

                usersStore.dispose();
                ucrStore.dispose();
                jobsStore.dispose();
                vaccinationsStore.dispose();
                routesStore.dispose();
                schedulesStore.dispose();
            }

            render() {
                const {
                    configStore: { loaded, isFeatureEnabled },
                    userStore: { isAdmin, isController, isSuperuser, isComplianceOfficer },
                } = this.props.RootStore;

                const { apolloClient } = this.state;

                if (!loaded) {
                    return (
                        <div>
                            <Loader />
                        </div>
                    );
                }

                let homePage = getHomepageTab(
                    isController,
                    isAdmin,
                    isSuperuser,
                    isComplianceOfficer,
                    isFeatureEnabled,
                );

                if (!homePage) {
                    // If the user has access to no content, redirect to Page not found
                    return <PageNotFound />;
                }

                if (apolloClient) {
                    return (
                        <ApolloProvider client={apolloClient}>
                            <Router props={this.props} homePage={homePage} />
                        </ApolloProvider>
                    );
                }

                return <Router props={this.props} homePage={homePage} />;
            }
        },
    ),
);

export default ControllerMain;
